import { useState, useEffect } from "react";
import { Container } from "../core";
import styles from "./Footer.module.scss";
import logo from "../../assets/img/logo-horiz.svg";
import Api from "api";
import { Link } from "react-router-dom";

const initialModuleOpts = {
  hasLogo: true,
  hasSitemap: true,
  hasContacts: true,
};
const initialState = {
  logo,
  sitemap: [
    { id: 1, label: "Home", path: "/" },
    { id: 2, label: "Simona Villa", path: "/simona-villa" },
    { id: 3, label: "Wedding Planner", path: "/wedding-planner" },
    { id: 4, label: "Eventi", path: "/eventi" },
    { id: 5, label: "Journal", path: "/journal" },
    { id: 6, label: "Contatti", path: "/contatti" },
  ],
};

const Footer = () => {
  const [content, setContent] = useState(initialState);
  const [address, setAddress] = useState({});
  const [pages, setPages] = useState([]);
  const [moduleOpts, setModuleOpts] = useState(initialModuleOpts);

  useEffect(() => {
    const fetchPages = async () => {
      const pages = await Api.pages.getList();
      setPages(pages);
    };

    setAddress({
      streetOne: "Via C.E. Gadda 3, Monza",
      streetTwo: "",
      vat: "10931570963",
      phone: "+393920041132",
      mail: "sevents.simona@gmail.com",
    });

    fetchPages();
    setContent(initialState);
    setModuleOpts(initialModuleOpts);
  }, []);

  const renderFooterLogo = () => {
    if (!moduleOpts.hasLogo) return null;
    return (
      <a className={styles.logo} href="/">
        Go to Homepage
        <img src={content.logo} alt="" />
      </a>
    );
  };
  const renderFooterSitemap = () => {
    if (!moduleOpts.hasSitemap) return null;
    return (
      <ul className={styles.sitemap}>
        {pages.map((link) => {
          if (link.isPublished)
            return (
              <li key={`footer-sitemap-${link.id}`}>
                <Link to={`/${link.slug}`}>
                  {link.label === "Homepage" ? "HOME" : link.label}
                </Link>
                <span>&ndash;</span>
              </li>
            );
          return null;
        })}
      </ul>
    );
  };
  const renderFooterContacts = () => {
    if (!moduleOpts.hasContacts) return null;
    return (
      <address className={styles.contacts}>
        {address.streetOne && (
          <p>
            {address.streetOne}
            <span> &ndash;</span>
          </p>
        )}
        {address.streetTwo && (
          <p>
            {address.streetTwo}
            <span> &ndash;</span>
          </p>
        )}
        {address.vat && (
          <p>
            P.IVA: {address.vat}
            <span> &ndash;</span>
          </p>
        )}
        {address.phone && (
          <p>
            Telefono: <a href={`tel:${address.phone}`}>{address.phone}</a>
            <span> &ndash;</span>
          </p>
        )}
        {address.mail && (
          <p>
            Mail:
            <a href="mailto:sevents.simona@gmail.com">
              sevents.simona@gmail.com
            </a>
            <span> &ndash;</span>
          </p>
        )}
      </address>
    );
  };

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerWrapper}>
          {renderFooterLogo()}
          {renderFooterSitemap()}
          {renderFooterContacts()}
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
