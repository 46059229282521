import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import classes from "./SplashImage.module.scss";

const SplashImage = (props) => {
  const { src = null, size = "big", altText = "", hasParallax = false } = props;
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView();

  const path = src
    ? `${process.env.REACT_APP_API_URL.replace("/api", "")}/${src.replace(
        "media/",
        ""
      )}`
    : "https://via.placeholder.com/1920x768";

  const backgroundOpts = () => {
    if (size === "small")
      return {
        backgroundImage: `url(${path})`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      };
    return null;
  };

  useEffect(() => {
    if (inView) {
      if (size === "big") {
        setAnimate(true);
      }
    }

    //eslint-disable-next-line
  }, [ref, inView]);

  return (
    <div
      ref={ref}
      className={`${classes.splashImage} 
      ${hasParallax ? classes.parallax : ""}
      ${classes[size]}
      `}
      style={backgroundOpts()}
    >
      <img
        src={path}
        alt={altText}
        className={`${animate ? classes.animate : ""} ${
          hasParallax && size === "small" ? classes.hidden : ""
        } `}
      />
    </div>
  );
};

export default SplashImage;
