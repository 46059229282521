import {
  createElement,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import Api from "api";
import { LanguageContext } from "contexts/languageContext";

import {
  Heading,
  Paragraph,
  Grid,
  NavLink,
  SplashImage,
  ExtLink,
  ImageAndText,
} from "components/modules";
import { Form } from "components/core";

import classes from "./CustomPage.module.scss";

const dictionary = {
  Heading: Heading,
  Paragraph: Paragraph,
  Grid: Grid,
  SplashImage: SplashImage,
  NavLink: NavLink,
  ExtLink: ExtLink,
  ImageText: ImageAndText,
};
const initialState = {
  label: "",
  slug: "",
  metaTitle: "",
  metaDescription: "",
};

const CustomPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { lang } = useContext(LanguageContext);

  const [page, setPage] = useState(initialState);
  const [content, setContent] = useState([]);
  const slug = location.pathname.replace(/^\//, "");

  useEffect(() => {
    const fetchPage = async () => {
      const PARAM_SLUG = slug || "homepage";
      const page = await Api.pages.getBySlug(PARAM_SLUG);
      if (!page.isPublished) history.goBack();
      setPage(page);
    };

    fetchPage();
    //eslint-disable-next-line
  }, [slug]);

  useEffect(() => {
    const fetchTranslation = async () => {
      const content = await Api.translations.getPageTranslation(
        page.slug,
        lang
      );
      setContent(content);
    };

    if (page.slug && lang) fetchTranslation();
  }, [page, lang]);

  const renderBlocks = () => {
    return content.map(({ blockType, wrapperSize = null, props }, index) => {
      const Component = createElement(dictionary[blockType], {
        ...props,
      });
      if (wrapperSize) {
        return (
          <div
            key={`custom-component-${index}-type-${blockType}`}
            className={
              wrapperSize === "small"
                ? classes.containerSmall
                : classes.container
            }
          >
            {Component}
          </div>
        );
      }
      return (
        <Fragment key={`custom-component-${index}-type-${blockType}`}>
          {Component}
        </Fragment>
      );
    });
  };

  return (
    <Fragment>
      <Helmet>
        {page?.metaTitle && <title>{page.metaTitle} | Simona Villa</title>}
        {page?.metaDescription && (
          <meta name="description" content={page.metaDescription} />
        )}
      </Helmet>
      <div
        style={{
          paddingTop: page?.hasHeroBlock ? 0 : "9rem",
          paddingBottom: "2rem",
        }}
      >
        {renderBlocks()}
        {page.slug === "eventi" ||
        page.slug === "wedding-planner" ||
        page.slug === "contacts" ? (
          <Form />
        ) : null}
        {/* {page.slug === "/" || page.slug === "homepage" ? (
          <InstagramFeed />
        ) : null} */}
      </div>
    </Fragment>
  );
};

export default CustomPage;
