import { Fragment, useState, useEffect } from "react";

import { Container } from "components/core";
import {
  Heading,
  SplashImage,
  Paragraph,
  Pagination,
} from "components/modules";
import Api from "api";

import { BlogList, BlogListItem } from "./components";

const paragraphContent = `Quello che io posso fare per il tuo matrimonio concretamente? Pianificare, coordinare, ricercare e offrire validi spunti che possono contribuire a rendere ancora più speciale la tua giornata; in una parola occuparmi di tutta l’organizzazione di un matrimonio a Monza Brianza o ovunque tu voglia. Affidarsi a un professionista del matrimonio come il wedding planner, in Italia o all’estero, significa risparmiare tempo, ridurre lo stress, diminuire i costi, avvicinarsi maggiormente a quello che è il proprio matrimonio ideale e godersi in tutto e per tutto la bellezza di un giorno unico.`;

const Journal = () => {
  const [list, setList] = useState([]);
  const [pagination, setPagination] = useState({
    totalItems: 11,
    currentPage: 0,
    itemsPerPage: 4,
    totalPages: 3,
  });

  useEffect(() => {
    const getList = async () => {
      const list = await Api.entities.getInCollection(2, {
        limit: pagination.itemsPerPage,
        page: 0,
      });
      setList(list.items);
      setPagination({
        totalItems: list.items.length,
        currentPage: 1,
        itemsPerPage: 1,
        totalPages: Math.ceil(list.items.length / 1),
      });
    };

    getList();
    //eslint-disable-next-line
  }, []);

  const handlePageChange = (newPage) => {
    if (
      pagination.currentPage < pagination.totalPages &&
      pagination.currentPage > 0
    )
      setPagination({
        ...pagination,
        currentPage: newPage,
      });
  };

  return (
    <Fragment>
      <SplashImage path="/location-hero.jpg" />
      <Container>
        <Heading weight={2} content="Journal" />
        <Paragraph content={paragraphContent} />

        <BlogList>
          {list.map((post, index) => (
            <BlogListItem {...post} key={index} />
          ))}
        </BlogList>

        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.currentPage}
          handlePageChange={handlePageChange}
        />
      </Container>
    </Fragment>
  );
};

export default Journal;
