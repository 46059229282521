import Api from "api";
import { LanguageContext } from "contexts/languageContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import classes from "./CaseHistoryListItem.module.scss";

const CaseHistoryListItem = ({ item }) => {
  const { lang } = useContext(LanguageContext);
  const [content, setContent] = useState({});
  const [thumbnail, setThumbnail] = useState(
    "https://via.placeholder.com/300x200"
  );

  useEffect(() => {
    const fetchTranslation = async () => {
      const content = await Api.translations.getEntityTranslation(
        item.id,
        lang
      );
      setContent(content);
      if (content.thumbnail)
        setThumbnail(
          `${process.env.REACT_APP_API_URL.replace(
            "/api",
            ""
          )}/${content.thumbnail.replace("media/", "")}`
        );
    };

    if (item.id) fetchTranslation();

    //eslint-disable-next-line
  }, [item.id, lang]);

  return (
    <Link to={`/case-history/${item.slug}`} className={classes.item}>
      <div className={classes.thumbnail}>
        <img src={thumbnail} alt="" />
      </div>
      <div className={classes.info}>
        <div>
          <h3>{content.eventType}</h3>
          <p>{content.eventDate}</p>
        </div>
        <div>
          <h3>{content.title}</h3>
          <p>{lang === "it_IT" ? "Scopri" : "Read more"} ››</p>
        </div>
      </div>
    </Link>
  );
};

export default CaseHistoryListItem;
