import { Fragment, useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../assets/img/logo-horiz.svg";
import logoAfter from "../../assets/img/logo-horiz-alt.svg";
import logoMain from "../../assets/img/logo-vert.svg";

import styles from "./Header.module.scss";
import { LanguageContext } from "contexts/languageContext";

const initialOpts = {
  isSticky: false,
  isFixed: true,
  hasLogo: true,
  logoAsLink: true,
};
const initialState = {
  logo,
  logoAfter,
  logoMain,
  nav: {},
  transformAt: 250,
};

const Header = () => {
  const [state, setState] = useState(initialState);
  const [transform, setTransform] = useState(false);
  const [moduleOpts, setOpts] = useState(initialOpts);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { lang, setLang } = useContext(LanguageContext);

  useEffect(() => {
    setState(initialState);
    setOpts(initialOpts);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setTransform(window.pageYOffset > state.transformAt);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [state.transformAt]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style = "overflow-y:hidden; position: relative;";
    } else {
      document.body.style = "overflow-y:visible; position: static;";
    }
  }, [isMenuOpen]);

  const handleNavigation = (e) => {
    setMenuOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <header
        className={`${styles.header} ${
          moduleOpts.isSticky ? styles.sticky : ""
        } ${moduleOpts.isFixed ? styles.fixed : ""} ${
          transform ? styles.transformed : ""
        } ${isMenuOpen ? styles.withMenu : ""}`}
      >
        <div className={styles.wrapper}>
          <Link
            to="/"
            className={`${styles.logo} ${
              moduleOpts.logoAsLink ? "" : styles.logoDisabled
            }`}
          >
            Go to Home
            <img src={logoAfter} alt="" />
          </Link>
          <Link
            to="/"
            className={`${styles.logo} ${
              moduleOpts.logoAsLink ? "" : styles.logoDisabled
            }`}
          >
            Go to Home
            <img src={logoMain} alt="" />
          </Link>
          <nav className={`${styles.nav} ${isMenuOpen ? styles.active : ""}`}>
            <div className={styles.lang}>
              <button
                onClick={() => setLang("it_IT")}
                className={lang === "it_IT" ? styles.active : ""}
              >
                ITA
              </button>
              <span>|</span>
              <button
                onClick={() => setLang("en_US")}
                className={lang === "en_US" ? styles.active : ""}
              >
                ENG
              </button>
            </div>
            <div
              className={styles.hamburgerIcon}
              onClick={() => setMenuOpen(!isMenuOpen)}
            >
              <span>Menu</span>
              <div>
                <div className={`${styles.icon}`}></div>
                <div className={`${styles.icon}`}></div>
                <div className={`${styles.icon}`}></div>
                <div className="clear"></div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div className={`${styles.menu} ${isMenuOpen ? styles.visible : ""}`}>
        <ul>
          <li>
            <NavLink to="/" onClick={handleNavigation}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/simona-villa" onClick={handleNavigation}>
              Simona Villa
            </NavLink>
          </li>
          <li>
            <NavLink to="/wedding-planner" onClick={handleNavigation}>
              Wedding Planner
            </NavLink>
          </li>
          <li>
            <NavLink to="/wild-charm-wedding" onClick={handleNavigation}>
              The Wild Charm Wedding
            </NavLink>
          </li>
          <li>
            <NavLink to="/eventi" onClick={handleNavigation}>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/locations" onClick={handleNavigation}>
              Locations
            </NavLink>
          </li>
          <li>
            <NavLink to="/case-history" onClick={handleNavigation}>
              Case History
            </NavLink>
          </li>
          <li>
            <NavLink to="/journal" onClick={handleNavigation}>
              Journal
            </NavLink>
          </li>
          <li>
            <NavLink to="/contacts" onClick={handleNavigation}>
              Contacts
            </NavLink>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Header;
