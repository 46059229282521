import Api from "api";
import { LanguageContext } from "contexts/languageContext";
import { useContext, useEffect, useState } from "react";
import classes from "./RelatedEntries.module.scss";

const RelatedEntries = ({ item }) => {
  const { lang } = useContext(LanguageContext);
  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchTranslation = async () => {
      const content = await Api.translations.getEntityTranslation(
        item.id,
        lang
      );
      setContent(content);
    };

    fetchTranslation();
    //eslint-disable-next-line
  }, [lang]);

  if (!content) return null;
  return (
    <div
      key={`related-blog-post-${item.id}`}
      className={classes.relatedEntriesItem}
    >
      <img
        src={
          content?.thumbnail
            ? `${process.env.REACT_APP_API_URL.replace(
                "/api",
                ""
              )}/${content?.thumbnail.replace("media/", "")}`
            : "https://via.placeholder.com/300x200"
        }
        alt={content.title}
      />
      {content.publishingDate && <p>{content.publishingDate}</p>}
      {content.title && <h2>{content.title}</h2>}
      {content.description && (
        <p dangerouslySetInnerHTML={{ __html: content.description }} />
      )}
      <a href={`/journal/${item.slug}`}>
        {lang === "it_IT" ? "Scopri" : "Read more"} ››
      </a>
    </div>
  );
};

export default RelatedEntries;
